//
// Welcome component
// --------------------------------------------------
// Generates a step by step onboarding experience


$ol-c-welcome__body--MarginLeft: $ol-global--spacer--xxxl/2 !default;

$ol-c-welcome__title--MarginBottom: $ol-global--spacer--xxs !default;

$ol-c-welcome__indicator--Height:      $ol-global--component--MinHeight !default;
$ol-c-welcome__indicator--Width:       $ol-c-welcome__indicator--Height !default;
$ol-c-welcome__indicator--MarginRight: $ol-global--spacer--xxxl/2 !default;

$ol-c-welcome__item--PaddingTop:             $ol-global--spacer--xxxl/2 !default;
$ol-c-welcome__item--PaddingBottom:          $ol-global--spacer--xxxl/2 !default;
$ol-c-welcome__item--hover--BackgroundColor: $ol-global--divider--Color !default;

$ol-c-welcome__item--BorderBottomWidth: $ol-global--BorderWidth !default;
$ol-c-welcome__item--BorderBottomColor: $ol-global--divider--Color !default;

$ol-c-welcome__indicator--BackgroundColor:               $ol-global--dark--BackgroundColor !default;
$ol-c-welcome__indicator--Color:                         $ol-global--text--light--Color !default;
$ol-c-welcome__indicator--FontWeight:                    $ol-global--bold--FontWeight !default;
$ol-c-welcome__indicator--is-completed--BackgroundColor: $ol-global--success--Color !default;
$ol-c-welcome__indicator--is-active--BackgroundColor:    $ol-global--active--BackgroundColor !default;

$ol-c-welcome__title--FontSize: $ol-global--sub-title--FontSize !default;
$ol-c-welcome__link--is-disabled--BackgroundColor: $ol-global--text--Color !default;


// TODO: change all references to global variables, replace them for variables scoped to this component

.ol-c-welcome {
  @include list-unstyled;

  &__item {
    display: flex;
    align-items: flex-start;
    padding-top: $ol-c-welcome__item--PaddingTop;
    padding-bottom: $ol-c-welcome__item--PaddingTop;
    border-bottom: $ol-c-welcome__item--BorderBottomWidth solid $ol-c-welcome__item--BorderBottomColor;

    &:hover {
      background: $ol-c-welcome__item--hover--BackgroundColor;
      // TODO: make this a global variable so we can reuse it on other components that have the same interaction.
    }

    &:last-child {
      border-bottom: 0;
    }

    &.ol-is-disabled {
      filter: opacity(.6); // variable
    }
  }

  &__indicator {
    background-color: $ol-c-welcome__indicator--BackgroundColor;
    color: $ol-c-welcome__indicator--Color;
    font-weight: $ol-c-welcome__indicator--FontWeight;
    height: $ol-c-welcome__indicator--Height;
    width: $ol-c-welcome__indicator--Width;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: $ol-c-welcome__indicator--MarginRight;

    .ol-is-completed & {
      background-color: $ol-c-welcome__indicator--is-completed--BackgroundColor;
    }

    .ol-is-active & {
      background-color: $ol-c-welcome__indicator--is-active--BackgroundColor;
    }
  }

  &__link {
    .ol-is-disabled &,
    .ol-is-completed & {
      pointer-events: none;
      color: $ol-c-welcome__link--is-disabled--BackgroundColor;
    }
  }

  &__body {
    flex: 1;
    margin-left: $ol-c-welcome__body--MarginLeft;
  }

  &__title {
    font-size: $ol-c-welcome__title--FontSize;
    margin-bottom: $ol-c-welcome__title--MarginBottom;
  }

  &__text {
    &:last-child {
      margin-bottom: 0;
    }
  }

}

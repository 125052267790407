//
// Main Page layout
// --------------------------------------------------


$ol-l-page__sidebar--Width: 15rem !default; // This is a magic number, there is no need for a global variable here.
$ol-l-page__sidebar--MinHeight: $ol-global--component--MinHeight !default;

$ol-l-page__sidebar--section--MarginBottom: $ol-global--spacer--xxl !default;

$ol-l-page__sidebar--BackgroundColor: $ol-global--dark--BackgroundColor !default;

// TODO: Make it work with flex for backwars compatibility

.ol-l-page {
  min-height: 100vh;

  &__sidebar {
    min-height: $ol-l-page__sidebar--MinHeight;
    background-color: $ol-l-page__sidebar--BackgroundColor;
    color: color-yiq($ol-l-page__sidebar--BackgroundColor);
    grid-area: nav;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      display: block;
      > * {
        margin-bottom: $ol-l-page__sidebar--section--MarginBottom;
      }
    }

    &.ol-is-open {
      transform: translateX(0);
    }
  }



  // If the browser supports grid then do the grid dance:

  @supports (display: grid) {

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: $ol-l-page__sidebar--Width 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "nav main";
    }

    &__sidebar {
      grid-area: nav;
    }

    &__main {
      grid-area: main;
    }
  }

  // if you are IE11 and you don't support grid, then do flex
  @supports not (display: grid) {

    @include media-breakpoint-up(md) {
      flex-flow: row;
      display: flex;

      &__sidebar {
        flex: 0 1 $ol-l-page__sidebar--Width;
      }
    }

    &__main {
      flex: 1;
    }


  }
}

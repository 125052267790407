//
// Secondary Navigation
// --------------------------------------------------
// this horizontal navigation can work anywhere like the page header.

$ol-c-secondary-nav--MinHeight: $ol-global--component--MinHeight !default;
$ol-c-secondary-nav__link--Color:   $ol-global--text--secondary--Color !default;
$ol-c-secondary-nav__link--light--Color: $ol-global--text--secondary-light--Color !default;

$ol-c-secondary-nav__link--PaddingLeft:   $ol-global--spacer--md !default;
$ol-c-secondary-nav__link--PaddingRight:  $ol-global--spacer--md !default;

$ol-c-secondary-nav__link--BorderWidth:         $ol-global--lg--BorderWidth !default;

$ol-c-secondary-nav__link--active--BorderColor:  $ol-global--link--Color !default;
$ol-c-secondary-nav__link--active--Color:        $ol-global--link--Color !default;

$ol-c-secondary-nav__link--hover--BorderColor:        $ol-global--divider--Color !default;
$ol-c-secondary-nav__link--light--hover--BorderColor: $ol-global--divider--light--Color !default;

$ol-c-secondary-nav__link--hover--Color:        $ol-global--text--secondary--Color !default;
$ol-c-secondary-nav__link--light--hover--Color: $ol-global--text--secondary-light--Color !default;

.ol-c-secondary-nav {
  display: flex;
  align-self: stretch;
  align-items: stretch;
  min-height: $ol-c-secondary-nav--MinHeight;
  @include list-unstyled;

  &__item {
    display: flex;
    align-items: stretch;
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-left: $ol-c-secondary-nav__link--PaddingLeft;
    padding-right: $ol-c-secondary-nav__link--PaddingRight;
    color: $ol-c-secondary-nav__link--Color;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $ol-c-secondary-nav__link--hover--Color;
      background-image: linear-gradient(
        to top,
        $ol-c-secondary-nav__link--hover--BorderColor
          $ol-c-secondary-nav__link--BorderWidth,
        transparent $ol-c-secondary-nav__link--BorderWidth
      );
    }

    &:active,
    &.ol-is-active {
      text-decoration: none;
      color: $ol-c-secondary-nav__link--active--Color;
      background-image: linear-gradient(
        to top,
        $ol-c-secondary-nav__link--active--BorderColor
          $ol-c-secondary-nav__link--BorderWidth,
        transparent $ol-c-secondary-nav__link--BorderWidth
      );
    }
  }
}


.ol-is-light {
  .ol-c-secondary-nav__link {
    color: $ol-c-secondary-nav__link--light--Color;

    &:hover,
    &:focus {
      color: $ol-c-secondary-nav__link--light--hover--Color;
      background-image: linear-gradient(
        to top,
        $ol-c-secondary-nav__link--light--hover--Color
          $ol-c-secondary-nav__link--BorderWidth,
        transparent $ol-c-secondary-nav__link--BorderWidth
      );
    }
    
    &:active,
    &.ol-is-active {
      text-decoration: none;
      color: $ol-c-secondary-nav__link--active--Color;
    }
  }
}

//
// Page Header
// --------------------------------------------------
//  The page header will inform the title of the page and place the submenu.

$ol-c-page-header--MinHeight: $ol-global--component--MinHeight !default; // Magic number!

$ol-c-page-header--BackgroundColor: $ol-global--background--Color !default;

$ol-c-page-header--PaddingLeft: $ol-global--spacer--xxxl / 2 !default;
$ol-c-page-header--PaddingRight: $ol-global--spacer--xxxl / 2 !default;

$ol-c-page-header--MarginBottom: $ol-global--spacer--md !default;
$ol-c-page-header--BorderWidth: $ol-global--BorderWidth !default;
$ol-c-page-header--BorderColor: $ol-global--divider--Color !default;

$ol-c-page-header__title--FontSize: $ol-global--sub-title--FontSize !default;
$ol-c-page-header__title--small--FontSize: $ol-global--body--FontSize !default;

$ol-c-page-header__title--small--PaddingInline: $ol-global--spacer--xs !default;

$ol-c-page-header--dark--BackgroundColor: $ol-global--dark--BackgroundColor !default;
$ol-c-page-header--dark--Color: $ol-global--text--light--Color !default;

.ol-c-page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-left: $ol-c-page-header--PaddingLeft;
  padding-right: $ol-c-page-header--PaddingRight;
  background-color: $ol-c-page-header--BackgroundColor;
  min-height: $ol-c-page-header--MinHeight;
  margin-bottom: $ol-c-page-header--MarginBottom;
  border-bottom: $ol-c-page-header--BorderWidth solid $ol-c-page-header--BorderColor;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: row;
  }


  > * {
    margin: 0;
    justify-content: center;
  }

  &__title {
    font-size: $ol-c-page-header__title--small--FontSize;
    padding-top: $ol-c-page-header__title--small--PaddingInline;
    padding-bottom: $ol-c-page-header__title--small--PaddingInline;

    @include media-breakpoint-up(sm) {
      font-size: $ol-c-page-header__title--FontSize;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .ol-is-dark {
    background-color: $ol-c-page-header--dark--BackgroundColor;
    color: $ol-c-page-header--dark--Color;
  }
}

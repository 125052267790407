@function ol-strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function ol-size-prem($pxval, $base: $ol-global--space-size--root) {
  @return ol-strip-unit($pxval) / $base * 1rem;
}

@function ol-font-prem($pxval, $base: $ol-global--font-size--root) {
  @return ol-strip-unit($pxval) / $base * 1rem;
}

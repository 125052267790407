//
// User Info
// --------------------------------------------------


$ol-c-user-info__link--FontSize: $ol-global--caption--FontSize !default;
$ol-c-user-info--PaddingLeft: $ol-global--spacer--xxxl/2 !default;
$ol-c-user-info--PaddingRight: $ol-global--spacer--xxxl/2 !default;

$ol-c-user-info--Color: $ol-global--text--Color !default;
$ol-c-user-info__link--Color: $ol-global--text--secondary--Color !default;
$ol-c-user-info__link--hover--Color: $ol-global--text--Color !default;

$ol-c-user-info--light--Color: $ol-global--text--light--Color  !default;
$ol-c-user-info__link--light--Color: $ol-global--text--secondary-light--Color !default;
$ol-c-user-info__link--hover--light--Color: $ol-global--text--light--Color  !default;

$ol-c-user-info__img--MaxWidth: 40px !default; // This is a magic number

$ol-c-user-info__img--MaxHeight: $ol-c-user-info__img--MaxWidth !default;
$ol-c-user-info__img--BorderRadius: 50% !default; // This is a magic number to make it rounded
$ol-c-user-info__img--MarginRight: $ol-global--spacer--sm !default;


.ol-c-user-info {
  display: none;
  padding-left: $ol-c-user-info--PaddingLeft;
  padding-right: $ol-c-user-info--PaddingRight;

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
  }

  &__img {
    max-width: $ol-c-user-info__img--MaxWidth; // 40px;
    max-height: $ol-c-user-info__img--MaxHeight; //40px;
    border-radius: $ol-c-user-info__img--BorderRadius; //10rem;
    display: block;
    margin-right: $ol-c-user-info__img--MarginRight; // .8rem;
  }

  &__body {
    flex: 1;
  }

  &__text {
    margin: 0;
  }

  &__link {
    font-size: $ol-c-user-info__link--FontSize;
    color: $ol-c-user-info__link--Color;

    &:hover {
      color: $ol-c-user-info__link--hover--Color;
    }
  }
}

.ol-is-light {
  .ol-c-user-info {
    &__text {
      color: $ol-c-user-info--light--Color;
    }

    &__link {
      font-size: $ol-c-user-info__link--FontSize;
      color: $ol-c-user-info__link--light--Color;

      &:hover {
        color: $ol-c-user-info__link--hover--light--Color;
      }
    }
  }
}

//
// Images modifier
// --------------------------------------------------
// Olly images classes

$ol-c-image--table--MaxHeight: 30px !default;
$ol-c-image--table__usericon--BorderRadius: 50% !default;

.ol-c-image {

  &--table {
    max-height: $ol-c-image--table--MaxHeight;

    &__usericon {
      border-radius: $ol-c-image--table__usericon--BorderRadius;
    }
  }
}

// olly.scss

// Required
// Olly variables mixins and functions
@import "ol-functions";
@import "ol-mixins";
@import "ol-variables";
@import "ol-bs-variables"; // Bootstrap variables overwrites

// Required
// These files don't generate code
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
// Bootstrap components
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";

// Optional
// Olly Layouts
@import "src/layouts/ol-l-page/ol-l-page";
@import "src/layouts/ol-l-bullseye/ol-l-bullseye";

// Olly Components
@import "src/components/ol-c-brand/ol-c-brand";
@import "src/components/ol-c-user-info/ol-c-user-info";
@import "src/components/ol-c-main-nav/ol-c-main-nav";
@import "src/components/ol-c-secondary-nav/ol-c-secondary-nav";
@import "src/components/ol-c-card/ol-c-card";
@import "src/components/ol-c-page-header/ol-c-page-header";
@import "src/components/ol-c-welcome/ol-c-welcome";
@import "src/components/ol-c-datatables/ol-c-datatables";
@import "src/components/ol-c-images/ol-c-images";
@import "src/components/ol-c-footer/ol-c-footer";

// Olly utility classes
@import "src/utilities/ol-u-rotate/ol-u-rotate";
@import "src/components/ol-c-datatables/ol-c-datatables";

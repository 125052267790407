//
// Card modifier
// --------------------------------------------------
// Olly extends bootstrap card to create the status card.

$ol-c-card--is-accented--BorderTopColor:     $ol-global--active--BorderColor !default;
$ol-c-card--is-accented--BorderTopWidth:     $ol-global--lg--BorderWidth !default;

$ol-c-card--is-accented--BorderBottomColor:     $ol-global--active--BorderColor !default;
$ol-c-card--is-accented--BorderBottomWidth:     $ol-global--sm--BorderWidth !default;

$ol-c-card--is-primary--BackgroundColor:        $ol-global--primary--Color !default;
$ol-c-card--is-secondary--BackgroundColor:      $ol-global--secondary--Color !default;
$ol-c-card--is-success--BackgroundColor:        $ol-global--success--Color !default;
$ol-c-card--is-info--BackgroundColor:           $ol-global--info--Color !default;
$ol-c-card--is-warning--BackgroundColor:        $ol-global--warning--Color !default;
$ol-c-card--is-danger--BackgroundColor:         $ol-global--danger--Color !default;
$ol-c-card--is-light--BackgroundColor:          $ol-global--light--Color !default;
$ol-c-card--is-dark--BackgroundColor:           $ol-global--dark--Color !default;

$ol-c-card--status__spotlight--FontSize:          $ol-global--main-title--FontSize !default;

$ol-c-card--status__spotlight-footer--BackgroundColor: $ol-global--white--Color !default;

$ol-c-card--status__spotlight-footer--Color: $ol-global--gray--600--Color !default;
$ol-c-card--status__spotlight-footer--FontSize: $ol-global--caption--FontSize !default;
$ol-c-card--status__spotlight-footer-anchor--FontWeight: $font-weight-bold !default;

/// Olly cards
/// @group components


.ol-c-card {
  &--status {
    border: 0;

    &__spotlight {
      font-size: $ol-c-card--status__spotlight--FontSize;
    }

    &.card-header {
      border: 0;
    }

    .card-body {
      border: 0;
      text-align: center;
    }

    .card-footer {
      border: 0;
      background-color: $ol-c-card--status__spotlight-footer--BackgroundColor;
      text-align: center;
      color: $ol-c-card--status__spotlight-footer--Color;
      font-size: $ol-c-card--status__spotlight-footer--FontSize;

      a {
        font-weight: $ol-c-card--status__spotlight-footer-anchor--FontWeight;
      }
    }
  }
}

.card {
  margin-bottom: $ol-global--spacer--xl;
  // TODO: investigate if we always need a margin on cards, my guess is that we do.

  &.ol-is-accented {
    border-top: $ol-c-card--is-accented--BorderTopWidth solid $ol-c-card--is-accented--BorderTopColor;
    border-bottom: $ol-c-card--is-accented--BorderBottomWidth solid $ol-c-card--is-accented--BorderBottomColor;
  }

  &.ol-is-primary {
    .card-header {
      background-color: $ol-c-card--is-primary--BackgroundColor;
      color: color-yiq($ol-c-card--is-primary--BackgroundColor);
    }

    .card-body {
      background-color: rgba($ol-c-card--is-primary--BackgroundColor, .80);
      color: color-yiq($ol-c-card--is-primary--BackgroundColor);
    }
  }

  &.ol-is-secondary {
    .card-header {
      background-color: $ol-c-card--is-secondary--BackgroundColor;
    }

    .card-body {
      background-color: rgba($ol-c-card--is-secondary--BackgroundColor, .80);
    }
  }

  &.ol-is-success {
    .card-header {
      background-color: $ol-c-card--is-success--BackgroundColor;
      color: color-yiq($ol-c-card--is-success--BackgroundColor);
    }

    .card-body {
      background-color: rgba($ol-c-card--is-success--BackgroundColor, .80);
      color: color-yiq($ol-c-card--is-success--BackgroundColor);
    }
  }

  &.ol-is-info {
    .card-header {
      background-color: $ol-c-card--is-info--BackgroundColor;
      color: color-yiq($ol-c-card--is-info--BackgroundColor);
    }

    .card-body {
      background-color: rgba($ol-c-card--is-info--BackgroundColor, .80);
      color: color-yiq($ol-c-card--is-info--BackgroundColor);
    }
  }

  &.ol-is-warning {
    .card-header {
      background-color: $ol-c-card--is-warning--BackgroundColor;
    }

    .card-body {
      background-color: rgba($ol-c-card--is-warning--BackgroundColor, .70);
    }
  }

  &.ol-is-danger {
    .card-header {
      background-color: $ol-c-card--is-danger--BackgroundColor;
      color: color-yiq($ol-c-card--is-danger--BackgroundColor);
    }

    .card-body {
      background-color: rgba($ol-c-card--is-danger--BackgroundColor, .80);
      color: color-yiq($ol-c-card--is-danger--BackgroundColor);
    }
  }

  &.ol-is-light {
    .card-header {
      background-color: rgba($ol-c-card--is-dark--BackgroundColor, .20);
    }

    .card-body {
      background-color: $ol-c-card--is-light--BackgroundColor;
    }
  }

  &.ol-is-dark {
    .card-header {
      background-color: $ol-c-card--is-dark--BackgroundColor;
      color: color-yiq($ol-c-card--is-dark--BackgroundColor);
    }

    .card-body {
      background-color: rgba($ol-c-card--is-dark--BackgroundColor, .8);
      color: color-yiq($ol-c-card--is-dark--BackgroundColor);
    }
  }
}

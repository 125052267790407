// Mixins
// Colot theme for navbar


@mixin ol-main-nav-variant($primary-color, $secondary-color, $hover-color: $primary-color, $active-color: $primary-color) {
  &__title {
    color: $secondary-color;
  }

  &__link {
    color: $primary-color;

    &:hover {
      color: $hover-color;
      text-decoration: none;
    }

    &.ol-is-active {
      color: $active-color;
    }
  }
}

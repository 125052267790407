//
// Main Navigation
// --------------------------------------------------
//  The main navigation is a vertical navigation that goes in the sidebar.


$ol-c-main-nav--Transition: $ol-global--base--Transition !default;

$ol-c-main-nav--small--BackgroundColor:   $ol-global--black--Color !default;
$ol-c-main-nav--small--Top:               $ol-global--component--MinHeight !default;
$ol-c-main-nav--small--PaddingTop:        $ol-global--spacer--xl !default;

$ol-c-main-nav__title--FontSize:     $ol-global--caption--FontSize !default;
$ol-c-main-nav__title--PaddingLeft:  $grid-gutter-width/2 !default;
$ol-c-main-nav__title--light--Color: $ol-global--text--secondary-light--Color !default;

$ol-c-main-nav__link--PaddingTop: $ol-global--spacer--xs !default;
$ol-c-main-nav__link--PaddingBottom: $ol-global--spacer--xs !default;

$ol-c-main-nav__link--Color: $ol-global--text--secondary--Color !default;
$ol-c-main-nav__link--hover--Color: $ol-global--text--Color !default;
$ol-c-main-nav__link--active--Color: $ol-global--text--Color !default;
$ol-c-main-nav__link--disabled--Color: $ol-global--disabled--Color !default;
$ol-c-main-nav__link--active--BackgroundColor: rgba(255,255,255,.075) !default;

$ol-c-main-nav__link--light--Color: $ol-global--text--secondary-light--Color !default;
$ol-c-main-nav__link--light--hover--Color: $ol-global--text--light--Color !default;
$ol-c-main-nav__link--light--active--Color: $ol-c-main-nav__link--light--hover--Color !default;
$ol-c-main-nav__link--light--disabled--Color: $ol-global--disabled--light--Color !default;

$ol-c-main-nav__section--MarginBottom:  $ol-global--spacer--xxxl !default;
$ol-c-main-nav__section--BorderWidth:   $ol-global--BorderWidth !default;
$ol-c-main-nav__section--BorderColor:   $ol-global--divider--Color !default;

$ol-c-main-nav__section--light--BorderColor: $ol-global--divider--light--Color !default;

/// Vertical Navigation should be use to bla bla bla bla
/// @group components

.ol-c-main-nav {
  background-color: $ol-c-main-nav--small--BackgroundColor;
  position: absolute;
  z-index: 1;
  width: 100%;
  transform: translateX(-100%);
  transition: $ol-c-main-nav--Transition;
  padding-top: $ol-c-main-nav--small--PaddingTop;
  top: $ol-c-main-nav--small--Top;
  bottom: 0;

  &.ol-is-open {
    transform: translateX(0);
  }

  @include media-breakpoint-up(md) {
    background-color: transparent;
    position: static;
    transform: translateX(0);
    padding-top: 0;
  }

  &__section {
    margin-bottom: $ol-c-main-nav__section--MarginBottom;
    border-bottom: $ol-c-main-nav__section--BorderWidth solid $ol-c-main-nav__section--BorderColor;
    &:last-of-type {
      border: none;
    }
  }

  &__title {
    padding-left: $ol-c-main-nav__title--PaddingLeft;
    font-size: $ol-c-main-nav__title--FontSize;
    font-weight: $ol-global--caption--FontSize;
    text-transform: uppercase;
  }

  &__list {
    @include list-unstyled;
  }

  &__link {
    padding-left: $grid-gutter-width/2;
    display: flex;
    align-items: center;
    // height: 4ex;
    padding-top: $ol-c-main-nav__link--PaddingTop;
    padding-bottom: $ol-c-main-nav__link--PaddingBottom;
    color: $ol-c-main-nav__link--Color;

    &:hover,
    &:focus {
      color: $ol-c-main-nav__link--hover--Color;
      text-decoration: none;
    }

    &.ol-is-active {
      color: $ol-c-main-nav__link--active--Color;
      background-color: $ol-c-main-nav__link--active--BackgroundColor;
    }

    &.ol-is-disabled {
      color: $ol-c-main-nav__link--disabled--Color;
    }
  }
}

// light menu
.ol-is-light {
  .ol-c-main-nav {

    &__section {
      border-color: $ol-c-main-nav__section--light--BorderColor;
    }

    &__title {
      color: $ol-c-main-nav__title--light--Color;
    }

    &__link {
      color: $ol-c-main-nav__link--light--Color;

      &:hover,
      &:focus {
        color: $ol-c-main-nav__link--light--hover--Color;
      }

      &.ol-is-active {
        color: $ol-c-main-nav__link--light--active--Color;
      }

      &.ol-is-disabled {
        color: $ol-c-main-nav__link--light--disabled--Color;
      }
    }
  }
}

// This file is for initial variable values.
// All variables should have a valid css value,
// and use !default so they can be overwritten

// `$ol-global--concept--state--size--PropiertyCamelCase`

// Olly colors

// Colors
// This colors system and pallet is based on material deisgn color system
// https://material.io/guidelines/style/color.htm

$ol-global--white--Color: #fff !default;
$ol-global--black--Color: #000 !default;


$ol-global--primary--Color:                  #039BE5 !default; // Blue 500
$ol-global--primary--dark--Color:             #1976D2 !default; // Blue 700
$ol-global--primary--light--Color:            #64B5F6 !default; // Blue 300
$ol-global--accent--Color:                   #009688 !default; // Teal 500

$ol-global--text--Color:                     rgba($ol-global--black--Color, .87) !default;
$ol-global--text--secondary--Color:           rgba($ol-global--black--Color, .54) !default;
$ol-global--disabled--Color:            rgba($ol-global--black--Color, .38) !default;
$ol-global--divider--Color:             rgba($ol-global--black--Color, .12) !default;

$ol-global--text--light--Color:             $ol-global--white--Color !default;
$ol-global--text--secondary-light--Color:   rgba($ol-global--white--Color, .70) !default;
$ol-global--disabled--light--Color:    rgba($ol-global--white--Color, .50) !default;
$ol-global--divider--light--Color:     rgba($ol-global--white--Color, .12) !default;

$ol-global--background--Color:            #fafafa !default;
$ol-global--dark--BackgroundColor:       #212121 !default;


// Gray
$ol-global--gray--100--Color: #F5F5F5 !default;
$ol-global--gray--200--Color: #EEEEEE !default;
$ol-global--gray--300--Color: #E0E0E0 !default;
$ol-global--gray--400--Color: #BDBDBD !default;
$ol-global--gray--500--Color: #9E9E9E !default;
$ol-global--gray--600--Color: #757575 !default;
$ol-global--gray--700--Color: #616161 !default;
$ol-global--gray--800--Color: #424242 !default;
$ol-global--gray--900--Color: #212121 !default;

$ol-global--secondary--Color:     $ol-global--gray--400--Color !default;
$ol-global--success--Color:       #7CB342 !default;
$ol-global--info--Color:          #00BCD4 !default;
$ol-global--warning--Color:       #FDD835 !default;
$ol-global--danger--Color:        #F44336 !default;
$ol-global--light--Color:         $ol-global--gray--200--Color !default;
$ol-global--dark--Color:          $ol-global--gray--900--Color !default;



// Olly colors - links
$ol-global--link--Color: $ol-global--primary--Color !default;

// Olly uses the default root size of the browser, it assumes a 16px base
// since most browser root size is 16. This variables is just to make the
// transformation from px to rem.
$ol-global--font-size--root:  16 !default; //for fonts
$ol-global--space-size--root: 16 !default; // for spaces


// Spacers
//
// Use these spacers to define all margins and paddings

$ol-global--spacer--xxxs:   ol-size-prem(2px) !default; // .125rem (12.5%)
$ol-global--spacer--xxs:    ol-size-prem(4px) !default; // .25rem  (25%)
$ol-global--spacer--xs:     ol-size-prem(8px) !default; // .50rem  (50%)
$ol-global--spacer--sm:     ol-size-prem(12px) !default; // .75rem  (75%)
$ol-global--spacer--md:     ol-size-prem(16px) !default; // 1rem    (100%)
$ol-global--spacer--lg:     ol-size-prem(20px) !default; // 1.25rem (125%)
$ol-global--spacer--xl:     ol-size-prem(24px) !default; // 1.5rem  (150%)
$ol-global--spacer--xxl:    ol-size-prem(28px) !default; // 1.75rem (175%)
$ol-global--spacer--xxxl:   ol-size-prem(32px) !default; // 2rem    (200%)

// Page

$ol-global--base--Transition: all .2s ease-in-out !default;


// Page
//

// $ol-global-page-border-color: $ol-color-gray-100 !default;

// Components
//

// $ol-global-component-color:             $ol-color-text !default;
// $ol-global-component-light-color:       $ol-color-text-secondary !default;
// $ol-global-component-background-color:  $ol-color-background !default;


// Border
//

// $ol-global-border-color:      $ol-global--divider--Color !default;
$ol-global--BorderWidth:      1px !default;
$ol-global--lg--BorderWidth:   4px !default;
$ol-global--sm--BorderWidth:   2px !default;

// $ol-global-component-inverse-color:       $ol-color-text-inverse !default;
// $ol-global-component-inverse-light-color: $ol-color-text-secondary-inverse !default;
// $ol-global-component-inverse-background-color: $ol-color-background-inverse !default;
// $ol-global-component-inverse-border-color: $ol-color-text-divider-inverse !default;

$ol-global--component--MinHeight: 3.5rem !default;


// States

// $ol-global--hover--Color:                   $ol-color-red-100 !default;
// $ol-global-hover-background-color:        $ol-color-blue-100 !default;
// $ol-global-hover-border-color:            $ol-color-red-300 !default;

$ol-global--hover--Color:          $ol-global--primary--light--Color !default;

// $ol-global-active-color:              $ol-color-primary !default;
$ol-global--active--BackgroundColor:      $ol-global--primary--Color !default;
$ol-global--active--BorderColor:          $ol-global--primary--Color !default;

// $ol-global--disabled--Color:                $ol-global--disabled--Color !default;
// $ol-global-disabled-background-color:     $ol-global--divider--light--Color !default;
// $ol-global-disabled-border-color:         $ol-color-black-200 !default;

// $ol-global-disabled-light-color:       $ol-color-text-disabled-inverse !default;

// $ol-global--success--Color:       $ol-color-success !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
// stylelint-disable value-keyword-case

// Imports Noto font family
@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700");

$ol-global--sans-serif--FontFamily: "Noto Sans", Noto Sans, -apple-system,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

// stylelint-enable value-keyword-case

$ol-global--base--FontSize: ol-font-prem(16px) !default;

$ol-global--hero-title--FontSize:       ol-font-prem(48px) !default;
$ol-global--main-title--FontSize:       ol-font-prem(32px) !default;
$ol-global--secondary-title--FontSize:  ol-font-prem(24px) !default;
$ol-global--sub-title--FontSize:        ol-font-prem(19px) !default;
$ol-global--body--FontSize:             ol-font-prem(16px) !default;
$ol-global--caption--FontSize:          ol-font-prem(14px) !default;

$ol-global--normal--FontWeight: 400 !default;
$ol-global--bold--FontWeight:   700 !default;

$ol-global--sm--LineHeight:   1.2 !default;
$ol-global--md--LineHeight: 1.5 !default;
$ol-global--lg--LineHeight:   1.8 !default;




// ============ lobal variables ==============
// Olly components    |     Boostrap components



// ==========================================
// Material design color System

// Dark   3\00
// Color  500
// Light  700
// Accent color
// grays for fonts and backgrounds.

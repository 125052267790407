//
// Bullseye layout
// --------------------------------------------------
// This layout center the element in the middle of the screen


.ol-l-bullseye {
  display: flex;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media-breakpoint-up(sm) {
    justify-content: center;
  }
  > * {
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: 30rem;
      min-width: 30rem;
    }
  }
}

//
// Footer
// --------------------------------------------------

$ol-c-footer--Border-Top-Color: $ol-global--divider--light--Color !default;
$ol-c-footer--Padding-Top: $ol-global--spacer--lg !default;
$ol-c-footer--Font-Size: $ol-global--caption--FontSize !default;
$ol-c-footer--Color: $ol-global--disabled--light--Color !default;

.ol-c-footer {
  border-top: 1px solid $ol-c-footer--Border-Top-Color;
  padding-top: $ol-c-footer--Padding-Top;
  font-size: $ol-c-footer--Font-Size;
  color: $ol-c-footer--Color;
  
  // These styles are hardcoded to global variables because they are reacting to their context.
  p {
    margin-bottom: $ol-global--spacer--xxs;
  }
  
  .ol-l-page__sidebar & {
    padding-left: $ol-c-page-header--PaddingLeft;
    padding-right: $ol-c-page-header--PaddingRight;
  }

}


//
// Brand Component
// --------------------------------------------------


$ol-c-brand-breakpoint-md--PaddingTop: $grid-gutter-width/2 !default;
$ol-c-brand-breakpoint-md--PaddingLeft: $grid-gutter-width/2 !default;
$ol-c-brand-breakpoint-md--PaddingRight: $grid-gutter-width/2 !default;
$ol-c-brand-img--MaxHeight: 2rem !default; //--CUSTOMIZE DEPENDING ON THE PROJECT LOGO


.ol-c-brand {
  display: flex;
  align-items: center;
  @include media-breakpoint-up(md) {
    padding-top: $ol-c-brand-breakpoint-md--PaddingTop;
    padding-left: $ol-c-brand-breakpoint-md--PaddingLeft;
    padding-right: $ol-c-brand-breakpoint-md--PaddingRight;
  }

  &__img {
    max-width: 100%;
    max-height: $ol-c-brand-img--MaxHeight;
    display: block;
    text-align: left;
  }
}
